import axios from "axios";

const user = "Th3Shadowbroker";
const client = axios.create({
    baseURL: "https://api.github.com"
});
const placeholder = document.getElementById("contributions-placeholder");
const showcase = document.getElementById("constributions-showcase");
const columns = showcase.childNodes;

function reduceData(data) {
    return data.filter(fragment => fragment.description).map(fragment => {
        return {
            fullName: fragment.full_name,
            url: fragment.html_url,
            description: fragment.description,
            license: fragment.license?.spdx_id
        };
    });
}

function generateElement(repository) {
    const el = document.createElement("div");
    el.classList.add('repository')
    el.addEventListener('click', () => window.open(repository.url, 'blank'));

    const name = document.createElement('div');
    name.classList.add('repository-name');
    name.innerText = repository.fullName;

    const description = document.createElement('div');
    description.classList.add('repository-description');
    description.innerText = repository.description;

    const license = document.createElement('div');
    const licenseIcon = document.createElement('span');
    const licenseName = document.createElement('span');
    license.classList.add("repository-license")
    licenseIcon.classList.add('fas');
    licenseIcon.classList.add('fa-balance-scale');
    licenseIcon.classList.add('license-icon');
    licenseName.innerText = repository.license ?? 'N/A';
    license.appendChild(licenseIcon);
    license.appendChild(licenseName);

    el.appendChild(name);
    el.appendChild(description);
    el.appendChild(license);

    return el;
}

function toggleShowcase() {
    if (placeholder.classList.contains("hidden")) {
        placeholder.classList.remove("hidden");
        showcase.classList.add("hidden");
    } else {
        showcase.classList.remove("hidden");
        placeholder.classList.add("hidden");
    }
}

function populateShowcase(elements) {
    let column = 0;

    for (let element of elements) {
        if (column > 2) column = 0;
        columns[column].appendChild(element);
        column++;
    }
}

async function fetchRepositories() {
    const request = client.get(`/users/${user}/repos?sort=updated&per_page=20`);
    const data = (await request).data;
    const repositories = reduceData(data);
    const elements = repositories.slice(0, 6).map(repository => generateElement(repository));

    populateShowcase(elements);
    toggleShowcase();
}

fetchRepositories();